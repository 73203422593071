<template>
  <div class="page list">
    <div class="services">
      <div class="category">
        <label class="categoryName">
          <i class="el-icon--home" @click="jumpPage({ name: 'home' })"></i>
          <i class="el-icon--right"></i>
          <span>{{ cname }}</span>
        </label>
        <transition-group
          tag="div"
          class="items"
          enter-active-class="animated fadeIn"
          leave-active-class="animated fadeOut"
          v-if="services.length"
        >
          <serviceItem
            class="service-item"
            v-for="(service, key) in services"
            :key="service.id"
            :data="formatServiceItem(service)"
            :light-height="keyword ? true : false"
            :keyword="keyword"
          />
        </transition-group>
        <noData v-else />
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pagesize"
        :hide-on-single-page="true"
        :total="serviceTotal"
        :current-page="pageNum"
        @current-change="pageChange"
      ></el-pagination>
    </div>
    <div
      class="right_other"
      v-if="users.length || groups.length || enterprise.length"
    >
      <!-- user -->
      <div class="box" v-if="users.length">
        <label class="title">
          <i class="type el-icon-user-solid"></i>
          <i
            class="more el-icon--homeMore"
            v-if="users.length === 10"
            @click="
              jumpPage({ name: 'users', params: { categoryId: cid, type: 3 } })
            "
          ></i>
        </label>
        <ul class="items">
          <li v-for="(user, key) in users" :key="key">
            <img
              class="avatar-u"
              v-if="user.userAvatar"
              :src="formartImageUrl(user.userAvatar)"
              @click="
                routeJump({
                  name: 'personalCenter',
                  params: {
                    id: user.id
                  }
                })
              "
            />
            <i
              v-else
              class="avatar-u"
              @click="
                routeJump({
                  name: 'personalCenter',
                  params: {
                    id: user.id
                  }
                })
              "
            ></i>
            <span
              class="name"
              v-html="user.userName.slice(0, 20)"
              @click="
                routeJump({
                  name: 'personalCenter',
                  params: {
                    id: user.id
                  }
                })
              "
            ></span>
            <img
              v-if="
                user.country && user.country !== '0' && user.country !== '6'
              "
              class="country"
              :src="getCountryIcon(user.country)"
            />
          </li>
        </ul>
      </div>
      <!-- group -->
      <div class="box" v-if="groups.length">
        <label class="title">
          <i class="type el-icon--group"></i>
          <i
            class="more el-icon--homeMore"
            v-if="groups.length === 10"
            @click="
              jumpPage({
                name: 'list-ges',
                params: { categoryId: cid, type: 4 }
              })
            "
          ></i>
        </label>
        <ul class="items">
          <li v-for="(item, key) in groups" :key="key">
            <img
              class="avatar"
              v-if="item.avatar"
              :src="formartImageUrl(item.avatar)"
              @click="
                routeJump({ name: 'groupCenter', params: { id: item.id} })
              "
            />
            <i
              v-else
              class="avatar"
              @click="
                routeJump({ name: 'groupCenter', params: { id: item.id} })
              "
              >Melinked</i
            >
            <label>
              <span
                class="name"
                v-html="item.subject.slice(0, 20)"
                @click="
                  routeJump({ name: 'groupCenter', params: { id: item.id} })
                "
              ></span>
              <p class="desc" v-html="item.content"></p>
            </label>
          </li>
        </ul>
      </div>
      <!-- enter -->
      <div class="box" v-if="enterprise.length">
        <label class="title">
          <i class="type el-icon--company"></i>
          <i
            class="more el-icon--homeMore"
            v-if="enterprise.length === 10"
            @click="
              jumpPage({
                name: 'list-ges',
                params: { categoryId: cid, type: 5 }
              })
            "
          ></i>
        </label>
        <ul class="items">
          <li v-for="(item, key) in enterprise" :key="key">
            <img
              class="avatar"
              v-if="item.avatar"
              :src="formartImageUrl(item.avatar)"
              @click="
                jumpPage({ name: 'detail', params: { id: item.id, type: 5 } })
              "
            />
            <i
              v-else
              class="avatar"
              @click="
                jumpPage({ name: 'detail', params: { id: item.id, type: 5 } })
              "
              >Melinked</i
            >
            <label>
              <span
                class="name"
                v-html="item.subject.slice(0, 20)"
                @click="
                  jumpPage({ name: 'detail', params: { id: item.id, type: 5 } })
                "
              ></span>
              <p class="desc" v-html="item.content"></p>
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import serviceItem from "@/components/business/web-site/service-temp";
import noData from "@/components/business/web-site/not-data";
export default {
  data() {
    return {
      type: 0,
      cid: 0,
      cname: "",
      categorys: [],
      services: [],
      enterprise: [],
      groups: [],
      users: [],
      params: {},
      pageNum: 1,
      pagesize: 30,
      serviceTotal: 0,
      language: 1,
      keyword: "",
    };
  },

  components: {
    serviceItem,
    noData
  },
  computed: {
    watchCategorysId() {
      return this.$store.state.webStore.categoryId;
    },
    watchLocation() {
      return this.$store.state.webStore.location;
    }
  },
  watch: {
    watchCategorysId: {
      handler(val) {
        this.cid = val;
        this.params = {
          categoryId: val,
          country: this.$store.state.webStore.location[1] || "",
          searchKey: this.keyword || "",
          language: parseInt(window.localStorage.getItem("langCode"))
        };
        this.getPageData();
      },
      deep: true
    },
    watchLocation: {
      handler(val) {
        this.params.country = val[1];
        this.getPageData();
      },
      dreep: true
    }
  },
  created() {
    let routerParams = this.$route.params;
    let strongNav = window.localStorage.getItem("catchNavs");
    this.$store.dispatch("commitType", 0);
    this.cid = this.$store.state.webStore.categoryId;
    this.language = parseInt(window.localStorage.getItem("langCode"));

    if (routerParams.categoryId && !this.cid) {
      let cateid = parseInt(routerParams.categoryId);
      this.$store.dispatch("commitCateGoryId", cateid);
      this.cid = cateid;
    }
    this.type = this.$store.state.webStore.type;
    if (this.$store.getters.userInfo.id) {
      this.params.userId = this.$store.getters.userInfo.id;
    }
    this.params.language = this.language;
    this.params.categoryId = this.cid;
    this.params.country = this.$store.state.webStore.location[1] || "";
    this.categorys = JSON.parse(strongNav);
    if (routerParams.key) {
      this.keyword = routerParams.key;
      this.getServices();
    } else {
      this.getPageData();
    }
  },
  methods: {
    async getNewNavs() {
      let params = {
        language: this.language,
        parentId: this.cid
      };
      let result = await this.$http({
        withCredentials: false,
        url: "rest/home/v3/getCategories",
        method: "get",
        params: params
      });
      return result.data.data;
    },
    async getPageData() {
      let navs = await this.getNewNavs();

      this.cname = this.findCurrentCate(this.cid, navs);
      this.getServices();
      this.getUsers();
      this.getGroups();
      this.getEnterPrices();
    },
    pageChange(page) {
      this.getServices(page);
    },
    async getServices() {
      let pageNum = arguments[0] || 1;
      if (pageNum) {
        this.params.pageNum = this.pageNum = pageNum;
      } else {
        this.pageNum = 1;
      }
      let paramsArr = [];
      Object.keys(this.params).forEach(item => {
        let oneParams = [];

        oneParams.push(item);
        oneParams.push(this.params[item]);

        paramsArr.push(oneParams);
      });
      let newParams = {
        pageSize: this.pagesize,
        language: this.language,
        categoryId: this.cid,
        searchKey: this.keyword,
        userId: this.$store.getters.userInfo.id
      };
      paramsArr.forEach(item => {
        let name = item[0];
        let val = item[1];
        newParams[name] = val;
      });

      let result = await this.$http({
        url: "rest/list/v3/getServiceJobList",
        params: newParams,
        withCredentials: false
      });
      this.services = result.data || [];
      this.serviceTotal = result.total;
    },
    async getUsers() {
      let result = await this.$http({
        url: "rest/list/v3/getPersonList",
        params: this.params,
        withCredentials: false,
        categoryId: this.cid,
        userId: this.$store.getters.userInfo.id
      });
      this.users = result.data.length ? this.formartUser(result.data) : [];
    },
    async getGroups() {
      let result = await this.$http({
        url: "rest/list/v3/getGroupList",
        params: this.params,
        withCredentials: false,
        categoryId: this.cid,
        userId: this.$store.getters.userInfo.id
      });

      this.groups = result.data.length ? this.formartGruop(result.data) : [];
    },
    async getEnterPrices() {
      let result = await this.$http({
        url: "rest/list/v3/getEnterpriseList",
        params: this.params,
        withCredentials: false,
        categoryId: this.cid,
        userId: this.$store.getters.userInfo.id
      });
      this.enterprise = result.data.length
        ? this.formartEnterPrices(result.data)
        : [];
    },
    formartEnterPrices(arr) {
      let newArr = [];
      arr.forEach(element => {
        let group = {
          avatar: element.enterprise_logo,
          content:
            element.enterprise_info.length >= 25
              ? element.enterprise_info.slice(0, 25) + "..."
              : element.enterprise_info,
          id: element.id,
          subject: element.enterprise_name,
          tag: element.enterprise_tag
          // type: element.type
        };
        newArr.push(group);
      });
      return newArr;
    },
    formartGruop(arr) {
      let newArr = [];
      arr.forEach(element => {
        let group = {
          avatar: element.group_header,
          content:
            element.group_info.length >= 25
              ? element.group_info.slice(0, 25) + "..."
              : element.group_info,
          id: element.group_id,
          subject: element.group_name,
          tag: element.group_tag
          // type: element.type
        };
        newArr.push(group);
      });
      return newArr;
    },
    formartUser(arr) {
      let newArr = [];
      arr.forEach(element => {
        let user = {
          userAvatar: element.profile_photo,
          userName: element.name,
          ucId: element.uc_id,
          // type: element.type,
          id: element.provider_id,
          country: element.country,
          gender: element.gender
        };
        newArr.push(user);
      });
      return newArr;
    },
    formatServiceItem(data) {
      return {
        author: {
          name: data.name,
          thumb: data.profile_photo,
          countryCode: data.country,
          time: data.modify_time,
          providerId: data.provider_id
        },
        serviceInfo: {
          title: data.i_can_do,
          thumb: data.cover,
          desc:
            data.detail.length >= 120
              ? this.filterHtmlTags(data.detail).slice(0, 120) + "..."
              : this.filterHtmlTags(data.detail).slice(0, 120),
          tags: data.tags !== "" ? data.tags.split(",") : [],
          id: data.id,
          pkId: data.id,
          userId: data.provider_id
        },
        actionData: {
          id: data.id,
          zanNum: data.like_count,
          replyCount: data.reviewer_count,
          type: 1,
          likeStatus: data.like_id ? true : false,
          shareData: {
            title: data.i_can_do,
            thumb: data.cover,
            desc: data.detail.slice(0, 30),
            url: "/user/pai_" + this.$Base64.encode(data.id)
          }
        }
      };
    }
  }
};
</script>
<style lang="stylus" scoped>
// @import url('../assets/css/common.css');
.list {
  display: flex;
  flex-flow: row;

  .services {
    flex: 8;

    .category {
      display: flex;
      flex-flow: column;

      .categoryName {
        display: flex;
        flex-flow: row;
        font-size: 18px;
        margin: 1.5rem 0 0.9rem 0;
        // justify-content: space-between;
        align-items: center;
        padding: 0 0.9rem 0 0;

        span {
          font-weight: bold;
        }

        .el-icon--home {
          font-size: 1.2rem;
          color: #33cc66;
          cursor: pointer;

          // margin-right: 0.25rem;
          &:hover {
            opacity: 0.8;
          }
        }

        .el-icon--right {
          color: #666666;
          margin: 0 0.25rem;
        }
      }

      .items {
        display: flex;
        flex-wrap: wrap;
        border-radius: 5px;

        // flex-flow: row;
        .service-item {
          width: 49.75%;
          margin: 0 0.5% 0.5% 0;

          &:nth-child(2n) {
            margin-right: 0;
          }

          &:hover {
            // background: rgba(255, 255, 255, 0.7);
          }

          &:nth-child(2n) {
            // margin-right: 0;
          }
        }
      }
    }
  }

  .right_other {
    flex: 2.5;
    width: 280px;
    display: flex;
    flex-flow: column;
    padding-top: 68px;
    margin-left: 0.9rem;

    .box {
      background: #ffffff;
      padding: 15px;
      margin: 0.9rem 0 0;

      &:nth-child(1) {
        margin: 0;
      }

      .title {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;

        i {
          font-size: 1.5rem;

          &.type {
            color: #000000;
          }

          &.more {
            cursor: pointer;
            color: #c5c5c5;
            font-size: 2rem;

            &:hover {
              color: #999999;
            }
          }
        }
      }

      .items {
        display: flex;
        flex-flow: column;
        margin: 0.9rem 0 0;

        li {
          margin: 0.5rem 0 0;
          display: flex;
          flex-flow: row;

          &:hover {
            cursor: pointer;
            background: #fafafa;
          }

          .avatar {
            min-width: 5rem;
            width: 5rem;
            height: 4rem;
            object-fit: cover;
            margin-right: 0.5rem;
            background: #eeeeee;
            display: block;
            font-style: normal;
            line-height: 4rem;
            text-align: center;
            color: #ffffff;
            font-weight: 800;
            font-size: 0.98rem;
          }

          label {
            .name {
              font-size: 14px;
            }

            .desc {
              font-size: 12px;
              color: #333333;
              margin: 0.25rem 0;
            }
          }
        }
      }

      &:nth-child(1) {
        .items {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;

          li {
            // margin: 0.7rem 0;
            display: flex;
            flex-flow: column;
            align-items: center;
            width: 50%;
            padding: 0.5rem 0;

            .avatar-u {
              width: 5rem;
              height: 5rem;
              object-fit: cover;
              border-radius: 5rem;
              margin: 0;
            }

            .name {
              font-size: 12px;
              color: #222222;
              margin: 0.25rem 0;
            }

            .country {
              width: 19px;
              object-fit: cover;
              height: 12px;
              // margin: 0.25rem 0;
            }
          }
        }
      }
    }
  }
}
</style>
