var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page list" }, [
    _c(
      "div",
      { staticClass: "services" },
      [
        _c(
          "div",
          { staticClass: "category" },
          [
            _c("label", { staticClass: "categoryName" }, [
              _c("i", {
                staticClass: "el-icon--home",
                on: {
                  click: function($event) {
                    return _vm.jumpPage({ name: "home" })
                  }
                }
              }),
              _c("i", { staticClass: "el-icon--right" }),
              _c("span", [_vm._v(_vm._s(_vm.cname))])
            ]),
            _vm.services.length
              ? _c(
                  "transition-group",
                  {
                    staticClass: "items",
                    attrs: {
                      tag: "div",
                      "enter-active-class": "animated fadeIn",
                      "leave-active-class": "animated fadeOut"
                    }
                  },
                  _vm._l(_vm.services, function(service, key) {
                    return _c("serviceItem", {
                      key: service.id,
                      staticClass: "service-item",
                      attrs: {
                        data: _vm.formatServiceItem(service),
                        "light-height": _vm.keyword ? true : false,
                        keyword: _vm.keyword
                      }
                    })
                  }),
                  1
                )
              : _c("noData")
          ],
          1
        ),
        _c("el-pagination", {
          attrs: {
            background: "",
            layout: "prev, pager, next",
            "page-size": _vm.pagesize,
            "hide-on-single-page": true,
            total: _vm.serviceTotal,
            "current-page": _vm.pageNum
          },
          on: { "current-change": _vm.pageChange }
        })
      ],
      1
    ),
    _vm.users.length || _vm.groups.length || _vm.enterprise.length
      ? _c("div", { staticClass: "right_other" }, [
          _vm.users.length
            ? _c("div", { staticClass: "box" }, [
                _c("label", { staticClass: "title" }, [
                  _c("i", { staticClass: "type el-icon-user-solid" }),
                  _vm.users.length === 10
                    ? _c("i", {
                        staticClass: "more el-icon--homeMore",
                        on: {
                          click: function($event) {
                            return _vm.jumpPage({
                              name: "users",
                              params: { categoryId: _vm.cid, type: 3 }
                            })
                          }
                        }
                      })
                    : _vm._e()
                ]),
                _c(
                  "ul",
                  { staticClass: "items" },
                  _vm._l(_vm.users, function(user, key) {
                    return _c("li", { key: key }, [
                      user.userAvatar
                        ? _c("img", {
                            staticClass: "avatar-u",
                            attrs: {
                              src: _vm.formartImageUrl(user.userAvatar)
                            },
                            on: {
                              click: function($event) {
                                return _vm.routeJump({
                                  name: "personalCenter",
                                  params: {
                                    id: user.id
                                  }
                                })
                              }
                            }
                          })
                        : _c("i", {
                            staticClass: "avatar-u",
                            on: {
                              click: function($event) {
                                return _vm.routeJump({
                                  name: "personalCenter",
                                  params: {
                                    id: user.id
                                  }
                                })
                              }
                            }
                          }),
                      _c("span", {
                        staticClass: "name",
                        domProps: {
                          innerHTML: _vm._s(user.userName.slice(0, 20))
                        },
                        on: {
                          click: function($event) {
                            return _vm.routeJump({
                              name: "personalCenter",
                              params: {
                                id: user.id
                              }
                            })
                          }
                        }
                      }),
                      user.country &&
                      user.country !== "0" &&
                      user.country !== "6"
                        ? _c("img", {
                            staticClass: "country",
                            attrs: { src: _vm.getCountryIcon(user.country) }
                          })
                        : _vm._e()
                    ])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _vm.groups.length
            ? _c("div", { staticClass: "box" }, [
                _c("label", { staticClass: "title" }, [
                  _c("i", { staticClass: "type el-icon--group" }),
                  _vm.groups.length === 10
                    ? _c("i", {
                        staticClass: "more el-icon--homeMore",
                        on: {
                          click: function($event) {
                            return _vm.jumpPage({
                              name: "list-ges",
                              params: { categoryId: _vm.cid, type: 4 }
                            })
                          }
                        }
                      })
                    : _vm._e()
                ]),
                _c(
                  "ul",
                  { staticClass: "items" },
                  _vm._l(_vm.groups, function(item, key) {
                    return _c("li", { key: key }, [
                      item.avatar
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.formartImageUrl(item.avatar) },
                            on: {
                              click: function($event) {
                                return _vm.routeJump({
                                  name: "groupCenter",
                                  params: { id: item.id }
                                })
                              }
                            }
                          })
                        : _c(
                            "i",
                            {
                              staticClass: "avatar",
                              on: {
                                click: function($event) {
                                  return _vm.routeJump({
                                    name: "groupCenter",
                                    params: { id: item.id }
                                  })
                                }
                              }
                            },
                            [_vm._v("Melinked")]
                          ),
                      _c("label", [
                        _c("span", {
                          staticClass: "name",
                          domProps: {
                            innerHTML: _vm._s(item.subject.slice(0, 20))
                          },
                          on: {
                            click: function($event) {
                              return _vm.routeJump({
                                name: "groupCenter",
                                params: { id: item.id }
                              })
                            }
                          }
                        }),
                        _c("p", {
                          staticClass: "desc",
                          domProps: { innerHTML: _vm._s(item.content) }
                        })
                      ])
                    ])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _vm.enterprise.length
            ? _c("div", { staticClass: "box" }, [
                _c("label", { staticClass: "title" }, [
                  _c("i", { staticClass: "type el-icon--company" }),
                  _vm.enterprise.length === 10
                    ? _c("i", {
                        staticClass: "more el-icon--homeMore",
                        on: {
                          click: function($event) {
                            return _vm.jumpPage({
                              name: "list-ges",
                              params: { categoryId: _vm.cid, type: 5 }
                            })
                          }
                        }
                      })
                    : _vm._e()
                ]),
                _c(
                  "ul",
                  { staticClass: "items" },
                  _vm._l(_vm.enterprise, function(item, key) {
                    return _c("li", { key: key }, [
                      item.avatar
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.formartImageUrl(item.avatar) },
                            on: {
                              click: function($event) {
                                return _vm.jumpPage({
                                  name: "detail",
                                  params: { id: item.id, type: 5 }
                                })
                              }
                            }
                          })
                        : _c(
                            "i",
                            {
                              staticClass: "avatar",
                              on: {
                                click: function($event) {
                                  return _vm.jumpPage({
                                    name: "detail",
                                    params: { id: item.id, type: 5 }
                                  })
                                }
                              }
                            },
                            [_vm._v("Melinked")]
                          ),
                      _c("label", [
                        _c("span", {
                          staticClass: "name",
                          domProps: {
                            innerHTML: _vm._s(item.subject.slice(0, 20))
                          },
                          on: {
                            click: function($event) {
                              return _vm.jumpPage({
                                name: "detail",
                                params: { id: item.id, type: 5 }
                              })
                            }
                          }
                        }),
                        _c("p", {
                          staticClass: "desc",
                          domProps: { innerHTML: _vm._s(item.content) }
                        })
                      ])
                    ])
                  }),
                  0
                )
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }